import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '../elements'
import { theme } from '../styles'
import { Tag } from '../utils/types'
import { readableColor } from 'polished'

export interface MenuProps {
  tag?: Tag
  root: {
    link: string
  }
  now: {
    link: string
    title: string
  }
  history: {
    link: string
    title: string
  }
  moments: {
    link: string
    title: string
  }
  services: {
    link: string
    title: string
  }
  about: {
    link: string
    title: string
  }
  block: {
    link: string
    title: string
  }
  jokai7: {
    link: string
    title: string
  }
  ajandekterm: {
    link: string
    title: string
  }
  contact: {
    link: string
    title: string
  }
}

export const DesktopContainer = styled.nav`
  display: none;
  position: fixed;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: ${theme.breakpoints[2]}) {
    display: block;
  }
`

const DesktopNavLink = styled(Link)`
  font-weight: semibold;
  font-size: 12px;
  text-align: center;
  color: ${theme.colors.white};
  background-color: ${theme.colors.black};
  text-transform: uppercase;
  padding: 10px 12px;
  margin: 0 1px;
  min-width: 112px;

  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }

  &.active {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }
`

const DesktopNavA = styled.a`
  font-weight: semibold;
  font-size: 12px;
  text-align: center;
  color: ${theme.colors.white};
  background-color: ${theme.colors.black};
  text-transform: uppercase;
  padding: 10px 12px;
  margin: 0 1px;
  min-width: 112px;

  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }

  &.active {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }
`

/* const LanguageLink = styled(Link)`
  font-weight: semibold;
  font-size: 12px;
  text-align: center;
  color: ${theme.colors.lightGrey};
  text-transform: uppercase;

  &:hover {
    color: ${theme.colors.white};
  }

  &.active {
    color: ${theme.colors.white};
  }
` */

/* const LanguageLinksContainer = () => (
  <Flex bg="black" mx="1px" py="8px" px="20px" width="108px" justifyContent="space-between" alignItems="center">
    <LanguageLink to="/hu" activeClassName="active" partiallyActive>
      hu
    </LanguageLink>
    <Box bg="white" width="1px" height="18px" />
    <LanguageLink to="/en" activeClassName="active" partiallyActive>
      en
    </LanguageLink>
  </Flex>
) */

export const DesktopMenu = ({ root, now, history, services, moments, block, jokai7, ajandekterm, about, contact, tag }: MenuProps) => (
  <Flex flexDirection="row" alignContent="stretch" justifyContent="stretch" m={2}>
    <>
      <Link to={root.link} style={{ position: 'fixed', marginLeft: '20px' }}>
        <img src="/logos/logo_hybridart.svg" width="150px" />
      </Link>
      {tag && (
        <Flex
          bg={tag.color}
          color={readableColor(tag.color)}
          ml="170px"
          width="150px"
          height="40px"
          fontSize="12px"
          alignItems="center"
          justifyContent="center"
          style={{ textTransform: 'uppercase' }}
        >
          {tag.name}
        </Flex>
      )}
    </>
    <Flex flexGrow={1} justifyContent="flex-end">
      <DesktopNavLink activeClassName="active" to={now.link}>
        {now.title}
      </DesktopNavLink>
      <DesktopNavLink activeClassName="active" to={history.link}>
        {history.title}
      </DesktopNavLink>
      <DesktopNavLink activeClassName="active" to={services.link}>
        {services.title}
      </DesktopNavLink>
      <DesktopNavLink activeClassName="active" to={moments.link}>
        {moments.title}
      </DesktopNavLink>
      <DesktopNavLink activeClassName="active" to={block.link}>
        {block.title}
      </DesktopNavLink>
      <DesktopNavA target="_blank" rel="noopener noreferrer" href={jokai7.link}>
        {jokai7.title}
      </DesktopNavA>
      <DesktopNavA target="_blank" rel="noopener noreferrer" href={ajandekterm.link}>
        {ajandekterm.title}
      </DesktopNavA>
      <DesktopNavLink activeClassName="active" to={about.link}>
        {about.title}
      </DesktopNavLink>
      <DesktopNavLink activeClassName="active" to={contact.link}>
        {contact.title}
      </DesktopNavLink>
    </Flex>
  </Flex>
)

const MobileNavLink = styled(Link)`
  font-weight: semibold;
  font-size: 16px;
  text-align: center;
  color: ${theme.colors.white};
  background-color: ${theme.colors.black};
  text-transform: uppercase;
  padding: 10px 24px;
  margin: 2px 14px;
  min-width: 94%;

  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }

  &.active {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }
`

const MobileNavA = styled.a`
  font-weight: semibold;
  font-size: 16px;
  text-align: center;
  color: ${theme.colors.white};
  background-color: ${theme.colors.black};
  text-transform: uppercase;
  padding: 10px 24px;
  margin: 2px 14px;
  min-width: 94%;

  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }

  &.active {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }
`

export const MobileContainer = styled.nav`
  display: block;
  position: fixed;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: ${theme.breakpoints[2]}) {
    display: none;
  }
`

const Icon = styled.img`
  margin: 0;
  padding: 16px 24px;
`

export const MobileMenu = ({ root, now, history, services, moments, about,jokai7, ajandekterm, contact, block, tag }: MenuProps) => {
  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <Flex m={2} bg={menuOpened ? 'black' : 'transparent'} flexDirection="column">
      <Flex flexDirection="row" justifyContent="space-between">
        <Box onClick={() => setMenuOpened(false)}>
          <Link to={root.link} style={{ padding: 0, position: 'fixed' }}>
            <img src="/logos/logo_hybridart.svg" width="50px" />
          </Link>
          {tag && (
            <Flex
              bg={tag.color}
              color={readableColor(tag.color)}
              ml="92px"
              width="150px"
              height="40px"
              fontSize="12px"
              alignItems="center"
              justifyContent="center"
              style={{ textTransform: 'uppercase' }}
            >
              {tag.name}
            </Flex>
          )}
        </Box>
        <Box bg="black" onClick={() => setMenuOpened(prev => !prev)}>
          <Icon src={menuOpened ? '/icons/close.svg' : '/icons/menu.svg'} alt="menu" />
        </Box>
      </Flex>
      {menuOpened && (
        <Flex height="100vh" width="100%" bg="black" flexDirection="column" alignItems="center" justifyContent="center">
          <MobileNavLink activeClassName="active" to={now.link} onClick={() => setMenuOpened(false)}>
            {now.title}
          </MobileNavLink>
          <MobileNavLink activeClassName="active" to={history.link} onClick={() => setMenuOpened(false)}>
            {history.title}
          </MobileNavLink>
          <MobileNavLink activeClassName="active" to={services.link}>
            {services.title}
          </MobileNavLink>
          <MobileNavLink activeClassName="active" to={moments.link}>
            {moments.title}
          </MobileNavLink>
          <MobileNavLink activeClassName="active" to={block.link}>
            {block.title}
          </MobileNavLink>
          <MobileNavA target="_blank" rel="noopener noreferrer" href={jokai7.link}>
            {jokai7.title}
          </MobileNavA>
          <MobileNavA target="_blank" rel="noopener noreferrer" href={ajandekterm.link}>
            {ajandekterm.title}
          </MobileNavA>
          <MobileNavLink activeClassName="active" to={about.link}>
            {about.title}
          </MobileNavLink>
          <MobileNavLink activeClassName="active" to={contact.link}>
            {contact.title}
          </MobileNavLink>
          {/* <LanguageLinksContainer /> */}
        </Flex>
      )}
    </Flex>
  )
}
