import React from 'react'
import { Box } from '../elements'
import { GridLayout } from './layout'
import { TeamMemberCard } from './cards/team-member-card'
import { IGatsbyImageData } from "gatsby-plugin-image"

interface TeamMembersProps {
  data: {
    job_position: string
    email: string
    phone_number: string
    profile_photo: {
      grayscale_image: { gatsbyImageData: IGatsbyImageData }
      original_image: { gatsbyImageData: IGatsbyImageData }
      alt: string
    }
    name: {
      text: string
    }
  }[]
}

export const TeamMembers = ({ data }: TeamMembersProps) => {
  return (
    <GridLayout>
      {data.map((teamMember, index) => {
        const {
          email,
          job_position,
          phone_number,
          profile_photo: { alt, grayscale_image, original_image },
          name,
        } = teamMember

        return (
          <Box key={index}>
            <TeamMemberCard
              name={name.text}
              originalImage={original_image}
              grayscaleImage={grayscale_image}
              alt={alt}
            />
            <Box py={2} mt={3}>
              {job_position.toLowerCase()}
            </Box>
            <Box py={2}>
              <a href={`mailto:${email}`}>{email}</a>
            </Box>
            <Box py={2}>
              <a href={`tel:${phone_number}`}>{phone_number}</a>
            </Box>
          </Box>
        )
      })}
    </GridLayout>
  )
}
