import { createGlobalStyle } from 'styled-components'
import { theme, reset } from '../styles'

export const GlobalStyles = createGlobalStyle`
  *::before,
  *::after {
    box-sizing: border-box;
  }
  /* ::selection {
    color: white;
    background-color: ${theme.colors.green};
  } */
  html {
    box-sizing: border-box;
    border: 0;
    margin: 0;

    @media (max-width: 600px) {
      h1 {
        font-size: ${theme.fontSizes[4]};
      }
      h2 {
        font-size: ${theme.fontSizes[3]};
      }
      h3 {
        font-size: ${theme.fontSizes[2]};
      }
      h4 {
        font-size: ${theme.fontSizes[1]};
      }
      h5 {
        font-size: ${theme.fontSizes[0]};
      }
      h6 {
        font-size: ${theme.fontSizes[0]};
      }
    }
  }
  body {
    border: 0;
    margin: 0;
    padding: 0;
    color: ${theme.colors.black};
    background: white;
  }
  html {
    scroll-behavior: smooth;
  }
  a {
    transition: all 0.3s ease-in-out;
    color: ${theme.colors.black};
    text-decoration: none;
  }
  p {
    line-height: 1.5;
    font-size: 14px;
  }

  ${reset}
`
