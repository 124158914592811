module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  title: 'Hybridart', // Navigation and Site Title
  titleAlt: 'Hybridart Management', // Alternative Site title for SEO
  titleShort: 'Hybridart', // short_name for manifest
  headline: 'Come & Enjoy our excellent photos', // Headline for schema.org JSONLD
  url: 'https://hybridart.hu', // Domain of your site. No trailing slash!
  language: 'hu', // Language Tag on <html> element
  logo: '/logos/logo.png', // Used for SEO and manifest
  description: 'Design and art management agency based in Budapest',
  author: 'Hybridart', // Author for schema.org JSONLD
  ogLanguage: 'hu', // og:language
  keywords: 'Art,Design,Management,Sustainability,Exhibition',

  twitter: '@hybridart', // Twitter Username
  facebook: 'Hybridartdesign', // Facebook Site Name
  googleAnalyticsID: 'UA-XXXpXXX-X',

  // Manifest and Progress color
  themeColor: '#db7436',
  backgroundColor: '#3b3c4f',

  // add your MC list endpoint here
  mailchimpEndpoint:
    'https://hybridart.us20.list-manage.com/subscribe/post?u=f5f129cf5932775bbb0cd1dde&amp;id=45d88b0795',
}
