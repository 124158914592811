import React from 'react'
import i18n from '../../config/i18n.js'
import { LanguageContextProps, Tag } from '../utils/types'
import { DesktopContainer, DesktopMenu, MenuProps, MobileContainer, MobileMenu } from './menu'

type NavigationProps = LanguageContextProps & { tag?: Tag }

export const Navigation = ({ lang, tag }: NavigationProps) => {
  const { now, history, services, moments, about, contact, block, path } = i18n[lang]
  const localizedPath = (subPath?: string): string => `${path}${subPath ? subPath : ''}`

  const menuProps: MenuProps = {
    root: { link: localizedPath('/') },
    now: { link: localizedPath('/#story'), title: now },
    history: { link: localizedPath('/#history'), title: history },
    moments: { link: localizedPath('/art-moments'), title: moments },
    services: { link: localizedPath('/services'), title: services },
    block: { link: localizedPath('/blokk'), title: block },
    jokai7: {link: "https://jokai7.hu/",title:"Jókai7" },
    ajandekterm: {link: "https://ajandekterminal.hu/",title:"Ajándékterminal" },
    about: { link: localizedPath('/about'), title: about },
    contact: { link: localizedPath('/contact'), title: contact },
    tag,
  }

  return (
    <>
      <MobileContainer>
        <MobileMenu {...menuProps} />
      </MobileContainer>
      <DesktopContainer>
        <DesktopMenu {...menuProps} />
      </DesktopContainer>
    </>
  )
}
