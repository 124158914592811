import { WindowLocation } from '@reach/router'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import i18n from '../../config/i18n.js'
import { Layout } from '../components/layout'
import SEO from '../components/SEO'
import { Box, Flex } from '../elements'
import { theme } from '../styles'
import { PageContextProps } from '../utils/types'

const SubmitButton = styled.input`
  cursor: pointer;
  transition: 500ms all;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding: 14px 16px;
  text-transform: uppercase;
  width: 100%;
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  border: 0;
  max-height: 40px;
  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  }
`

const Input = styled.input`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 10px 16px;
  max-height: 40px;
  width: 100%;
  color: ${theme.colors.black};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.black};
  border-color: ${props => (props.error ? 'red' : 'black')};
`

const Error = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: red;
`

interface Props {
  
  data: {
    prismicContact: {
      _previewable: boolean
      data: {
        description: {
          text: string
        }
        email_address: {
          text: string
        }
        management_location: {
          text: string
        }
        management_location_url: {
          text: string
        }
        phone_number: {
          text: string
        }
        space_location: {
          text: string
        }
        space_location_url: {
          text: string
        }
        title: {
          text: string
        }
        subscription_title: {
          text: string
        }
        subscription_description: {
          text: string
        }
        map_image: {
          gatsbyImageData: IGatsbyImageData
          alt: string
        }
      }
    }
  }
}

type ContactPageProps = Props & PageContextProps & { location: WindowLocation }

const ContactPage = ({ data, pageContext: { lang }, location }: ContactPageProps) => {
  const {
    description,
    email_address,
    management_location,
    management_location_url,
    phone_number,
    space_location,
    space_location_url,
    title,
    subscription_title,
    subscription_description,
    map_image,
  } = data.prismicContact.data

  const { email_required, email_invalid, successful_subscribe, subscribe, subscribe_error } = i18n[lang]
  const [buttonClicked, setButtonClicked] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [value, setValue] = useState('')

  const handleFormSubmit = async () => {
    if (!value) {
      setErrorMessage(email_required)
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      setErrorMessage(email_invalid)
    } else {
      const result = await addToMailchimp(value)
      result.result === 'success' || result.msg.includes('is already subscribed') ? setSuccess(true) : setSuccess(false)
      setButtonClicked(true)
      setErrorMessage('')
    }
  }

  return (
    <Layout lang={lang}>
      <SEO title={`${i18n[lang].contact} | Hybridart`} desc={description.text} pathname={location.pathname} />
      <Box style={{ width: '100%' }}>
        <h2 style={{ marginTop: '1.0rem' }}>{title.text}</h2>
        <p>{description.text}</p>
        <Flex flexDirection={['column', 'column', 'row']} alignItems="flex-start">
          <Box width={['100%', '100%', '60%']}>
            <GatsbyImage
              image={map_image.gatsbyImageData}
              alt={map_image.alt}
              style={{ maxHeight: '600px' }}
            />
          </Box>
          <Box ml={[0, 0, 5]} mt={[8, 8, 0]}>
            <Flex flexDirection="column" mb={6}>
              <Flex flexDirection={['column', 'row', 'column']} width="100%" mt={0}>
                <Box mb={6}>
                  <h4 style={{ marginBottom: '5px' }}>Hybridart Management</h4>
                  <a href={management_location_url.text} style={{ letterSpacing: '0.95px' }}>
                    {management_location.text}
                  </a>
                </Box>
                <Box ml={[0, 8, 0]}>
                  <h4 style={{ marginBottom: '5px' }}>Hybridart Space</h4>
                  <a href={space_location_url.text} style={{ letterSpacing: '0.95px' }}>
                    {space_location.text}
                  </a>
                </Box>
              </Flex>
              <Flex flexDirection="column" width="100%" mt={0}>
                <Box mt={[6, 6, 6]}>
                  <a href={`tel:${phone_number.text}`}>{phone_number.text}</a>
                </Box>
                <Box>
                  <a href={`mailto:${email_address.text}`}>{email_address.text}</a>
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <h2 style={{ marginTop: '32px' }}>{subscription_title.text}</h2>
        {!buttonClicked && (
          <>
            <label>E-MAIL</label>
            <Flex flexDirection={['column', 'row']}>
              <Box minWidth={['100%', '230px', '300px']}>
                <Input
                  type="email"
                  name="email"
                  value={value || ''}
                  error={errorMessage}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
                />
                {errorMessage && <Error>{errorMessage}</Error>}
              </Box>
              <Box ml={[0, 3, 9]} minWidth={['100%', '200px']}>
                <SubmitButton type="submit" value={subscribe} onClick={handleFormSubmit} />
              </Box>
            </Flex>
          </>
        )}
        {buttonClicked && success && (
          <Flex justifyContent="center" flexDirection="column">
            <img src="/icons/success.svg" />
            <p align="center">{successful_subscribe}</p>
          </Flex>
        )}
        {buttonClicked && !success && (
          <Flex justifyContent="center" flexDirection="column">
            <img src="/icons/error.svg" />
            <p align="center">{subscribe_error}</p>
          </Flex>
        )}
        <p>{subscription_description.text}</p>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery($lang: String!) {
    prismicContact(lang: { eq: $lang }) {
      _previewable
      data {
        description {
          text
        }
        email_address {
          text
        }
        management_location_url {
          text
        }
        management_location {
          text
        }
        phone_number {
          text
        }
        space_location_url {
          text
        }
        space_location {
          text
        }
        title {
          text
        }
        subscription_title {
          text
        }
        subscription_description {
          text
        }
        map_image {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
        }
      }
    }
  }
`
export default ContactPage
