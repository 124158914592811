import React, { FunctionComponent, ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import { Flex, Grid } from '../elements'
import { theme, GlobalStyles } from '../styles'
import { Navigation } from './navigation'
import { LanguageContextProps, Tag } from '../utils/types'
import Footer from './footer'

type LayoutProps = LanguageContextProps & { hero?: ReactNode; tag?: Tag, children: string | JSX.Element | JSX.Element[] }

type GridProps = {
  children: string | JSX.Element | JSX.Element[]; 
};

export const Layout: FunctionComponent<LayoutProps> = ({ children, lang, hero, tag}) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Navigation lang={lang} tag={tag} />
        {hero && hero}
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          pl={[4, 4, 4, 14]}
          pr={4}
          pb={4}
          pt={10}
          mr={[4, 4, 4, '45px', '218px']}
        >
          {children}
          <Footer lang={lang} />
        </Flex>
      </>
    </ThemeProvider>
  )
}

export const GridLayout: FunctionComponent<GridProps> = ({ children} ) => (
  <Grid pb={5} pr={[0, 0, 0, 0]} gridTemplateColumns="repeat(auto-fit, minmax(256px, 1fr))" gridGap={5}>
    {children}
  </Grid>
)
