import { graphql } from 'gatsby'
import React from 'react'
import { HeroImage } from '../components/hero-image'
import { Layout } from '../components/layout'
import { Box, Flex, Grid, Justify } from '../elements'
import { PageContextProps, Tag } from '../utils/types'
import { theme } from '../styles'
import { ParticipantCard } from '../components/cards/participant-card'
import i18n from '../../config/i18n.js'
import SEO from '../components/SEO'
import { WindowLocation } from '@reach/router'
import { IGatsbyImageData } from "gatsby-plugin-image"
import { ImageGallery } from '../components/gallery'

interface Props {
  data: {
    prismicProject: {
      _previewable: boolean
      first_publication_date: string
      last_publication_date: string
      data: {
        description: {
          html: string
        }
        end_date: string
        start_date: string
        abstract: string
        name: {
          text: string
        }
        original_cover: {
          gatsbyImageData: IGatsbyImageData
          url: string
        }
        duotone_cover: {
          gatsbyImageData: IGatsbyImageData
        }
        tags: {
          tag: {
            document: {
              data: Tag
            }
          }
        }[]
        body: any[]
      }
    }
  }
}

type ProjectTemplateProps =  Props & PageContextProps  & { location: WindowLocation }

const ProjectTemplate = ({ data, pageContext: { lang }, location }: ProjectTemplateProps) => {
  const { first_publication_date, last_publication_date } = data.prismicProject
  const { original_cover, duotone_cover, description, name, start_date, end_date, abstract } = data.prismicProject.data
  const localesData =
    data.prismicProject.data.body?.filter(value => value.slice_type === 'locale')
  const participantsData =
    data.prismicProject.data.body?.filter(value => value.slice_type === 'participant')
  const galleries = data.prismicProject.data.body?.filter(slice => slice.slice_type === 'image_gallery')

  const projectTag = data.prismicProject.data.tags
    .filter(tag => tag.tag)
    .map(tag => tag.tag.document?.data)
    .find(tag => tag?.type === 'project')

  const { participants, program } = i18n[lang]


  return (
    <Layout
      lang={lang}
      tag={projectTag}
      hero={<HeroImage original={original_cover.gatsbyImageData} duotone={duotone_cover.gatsbyImageData} />}
    >
      <SEO
        individual
        title={name.text}
        desc={abstract}
        pathname={location.pathname}
        banner={original_cover.url }
        node={{ birthTime: first_publication_date, modifiedTime: last_publication_date }}
      />
      <Box>
        <h2>{name.text}</h2>
        <Box fontSize="14px" fontWeight="bold" pb={3}>{`${start_date} - ${end_date}`}</Box>
        <Justify dangerouslySetInnerHTML={{ __html: description.html }} />
      </Box>
      <>{galleries.length > 0 && (
        <Box width="100%">
          <ImageGallery data={galleries} lang={lang}/>
        </Box>
      )}</>
      <>{localesData.length > 0 && (
        <Box width="100%">
          <h2>{program}</h2>
          <Grid gridGap={9} gridTemplateColumns="repeat(auto-fit, 320px)" mt={7}>
            {localesData.map((locale, index) => (
              <Box key={index} width="100%">
                <span style={{ color: theme.colors.blue, fontSize: '25px', fontWeight: 'bold' }}>
                  {locale.primary.date}
                </span>
                <h3>{locale.primary.name1.text.toUpperCase()}</h3>
                <Box borderTop="1px solid black">
                  {locale.items.map((item, index) => (
                    <Flex flexDirection="row" justifyContent="space-between" key={index}>
                      <Flex justifyContent="center" alignItems="center">
                        {item.event_timestamp.text}
                      </Flex>
                      <Flex
                        bg={index % 2 == 0 ? '#E3E4E3' : '#C4C4C4'}
                        width="70%"
                        height="64px"
                        px={6}
                        alignItems="center"
                      >
                        {item.event_name.text}
                      </Flex>
                    </Flex>
                  ))}
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      )}</>
      
      <>{participantsData.length > 0 && (
        <Box pt={6}>
          <Flex flexDirection="column" alignItems="flex-start">
            {participantsData.map((participant, index) => {
              if (
                participant.primary.image.gatsbyImageData &&
                participant.primary.description1.html &&
                participant.primary.name1.text
              ) {
                return (
                  <Box key={index} my={7}>
                    <ParticipantCard
                      name={participant.primary.name1.text}
                      desc={participant.primary.description1.html}
                      image={participant.primary.image.gatsbyImageData}
                    />
                  </Box>
                )
              }
            })}
          </Flex>
        </Box>
        
      )}</>
  
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($id: String!) {
    prismicProject(id: { eq: $id }) {
      _previewable
      id
      first_publication_date
      last_publication_date
      data {
        description {
          html
        }
        end_date(formatString: "DD/MM/YYYY")
        start_date(formatString: "DD/MM/YYYY")
        abstract
        name {
          text
        }
        original_cover: cover_photo {
          gatsbyImageData
          url
        }
        duotone_cover: cover_photo {
          gatsbyImageData
          url
        }
        tags {
          tag {
            document {
              ... on PrismicTag {
              data {
                color
                name
                type
                description {
                  text
                }
              }
            }
            }
          }
        }
        body {
          ... on PrismicProjectDataBodyImageGallery {
            slice_type
            primary { 
              type
              }
            items { 
              image { 
                thumb:gatsbyImageData (
                  width: 270
                  height: 270
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
            }
          }
          ... on PrismicProjectDataBodyLocale {
            slice_type
            primary {
              date
              name1 {
                text
              }
            }
            items {
              event_name {
                text
              }
              event_timestamp {
                text
              }
            }
          }
          ... on PrismicProjectDataBodyParticipant {
            slice_type
            primary {
              name1 {
                text
              }
              description1 {
                html
              }
              image {
                gatsbyImageData
                alt
              }
            }
          }
        }
      }
    }
  }
`



export default ProjectTemplate
