import { WindowLocation } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'
import i18n from '../../config/i18n.js'
import { Layout } from '../components/layout'
import { MediaAppearances } from '../components/media-appearances'
import SEO from '../components/SEO'
import { TeamMembers } from '../components/team-members'
import { Box, Justify } from '../elements'
import { PageContextProps } from '../utils/types'



interface Props {
  data: {
    prismicAbo: {
      _previewable: boolean
      data: {
        description: {
          html: string
          text: string
        }
        media_heading: {
          text: string
        }
        team_heading: {
          text: string
        }
        title: {
          text: string
        }
        body: any[]
      }
    }
  }
}

type AboutPageProps = Props & PageContextProps & { location: WindowLocation }

const AboutPage = ({ data, pageContext: { lang }, location }: AboutPageProps) => {
  const { description, media_heading, team_heading, title } = data.prismicAbo.data
  const slices = data.prismicAbo.data.body
  const teamMembers = slices.filter(slice => slice.slice_type === 'team_member').map(teamMember => teamMember.primary)
  const mediaAppearances = slices
    .filter(slice => slice.slice_type === 'media_appearance')
    .map(mediaAppearance => mediaAppearance.primary)

 
  return (
    <Layout lang={lang}>
      <SEO title={`${i18n[lang].about} | Hybridart`} desc={description.text} pathname={location.pathname} />
      <Box>
        <h2 style={{ marginTop: '1.0rem' }}>{title.text}</h2>
        <Justify dangerouslySetInnerHTML={{ __html: description.html }} />
        <h2>{team_heading.text}</h2>
        <TeamMembers data={teamMembers} />
        <Box mt={6}>
          <h2>{media_heading.text}</h2>
          <MediaAppearances data={mediaAppearances} />
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery($lang: String!) {
    prismicAbo(lang: { eq: $lang }) {
      _previewable
      data {
        description {
          html
          text
        }
        media_heading {
          text
        }
        team_heading {
          text
        }
        title {
          text
        }
        body {
          ... on PrismicAboDataBodyTeamMember {
            slice_type
            primary {
              job_position
              email
              phone_number
              profile_photo {
                grayscale_image: gatsbyImageData (imgixParams: {sat: -100})
                original_image: gatsbyImageData 
                alt
              }
              name {
                text
              }
            }
          }
          ... on PrismicAboDataBodyMediaAppearance {
            slice_type
            primary {
              article_title {
                text
              }
              date(formatString: "DD/MM/YY")
              link {
                url
              }
              medium {
                text
              }
              image {
                alt
                grayscale_image: gatsbyImageData (imgixParams: {sat: -100})
                original_image: gatsbyImageData 
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage
