import { WindowLocation } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'
import i18n from '../../config/i18n.js'
import { Layout } from '../components/layout'
import SEO from '../components/SEO/index'
import { Box,Button, Justify, Flex } from '../elements/index'
import { PageContextProps } from '../utils/types.js'
import styled from 'styled-components'
import { GatsbyImage,IGatsbyImageData } from "gatsby-plugin-image"
import { ImageGallery } from '../components/gallery'


interface Props {
  data: {
    prismicArtMoments: {
      _previewable: boolean
      data: {
        description: {
          html: string
        }
        title: {
          text: string
        }
        link_text: string
        link: { url :string}
        logo: {
          gatsbyImageData:  IGatsbyImageData
          alt: string
        }
        body: any[]
      }
    }
  }
}

type ArtMomentsPageProps = Props & PageContextProps & { location: WindowLocation }

const Image = styled(GatsbyImage)`
  width: 150px;
`

const ArtMomentsPage = ({ data, pageContext: { lang }, location }: ArtMomentsPageProps) => {
  const { description, title, link_text, link, logo, body } = data.prismicArtMoments.data
 
  const galleries = body?.filter(slice => slice.slice_type === 'image_gallery')

  return (
    <Layout lang={lang}>
      <SEO title={`${i18n[lang].moments} | Hybridart`} desc={description.text} pathname={location.pathname} />
      <Box>
        <h2 style={{ marginTop: '1.0rem' }}>{title.text}</h2>
        <Justify dangerouslySetInnerHTML={{ __html: description.html }} />
        {galleries && (<ImageGallery data={galleries} lang={lang}/>)}
        <Flex  mt={5} justifyContent="space-between">
        <Box>
          <a href={link.url}>
            <Button
              py={[0,3]}
              px={[0,3]}
              m={0}
              borderWidth={5} 
              borderColor="transparent"
              hoverColor="yellow"
              hoverTextColor="black"
              borderRadius={5}>
              {link_text}
              </Button>
          </a>
        </Box>
        <Box>
            <a href={link.url} target="_blank">
             <Image image={logo.gatsbyImageData} alt={logo.alt ? logo.alt : ''} />
           </a>
        </Box>
        </Flex>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ArtMomentsQuery($lang: String!) {
    prismicArtMoments(lang: { eq: $lang }) {
      _previewable
      data {
        description {
          html
        }
        title {
          text
        }
        link_text 
        link {
          url
        }
        logo {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          ... on PrismicArtMomentsDataBodyImageGallery {
            slice_type
            primary { 
              type
              }
            items { 
              image { 
                thumb:gatsbyImageData (
                  width: 270
                  height: 270
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
            }
          }
        }
      }
    }
  }
`

export default ArtMomentsPage