import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from '../../elements'
import { theme } from '../../styles'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"


const Container = styled(Box)`
  background-position: bottom right;
  background-size: cover;
`

const Background = styled(GatsbyImage)`
  width: 100%;
  height: 256px;
`

const ImageContainer = styled(Box)`
  position: relative;
`

const Title = styled.h3`
  position: absolute;
  top: 28px;
  margin-right: 128px;
  font-weight: 500;
  font-size: 16px;
  padding: 14px;
  text-transform: uppercase;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
`

interface MediaAppearanceCardProps {
  name: string
  image: { 
    original_image: { gatsbyImageData: IGatsbyImageData }
    grayscale_image: { gatsbyImageData: IGatsbyImageData }
  }
  alt?: string
  link: string
}


export const MediaAppearanceCard = ({ name, image, link }: MediaAppearanceCardProps) => {
  const [hovered, setHovered] = useState(false)

  return (
    <Container onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <a href={link}>
          <ImageContainer>
            <Background image={hovered ? image.original_image : image.grayscale_image} alt=''/>
            <Title>{name}</Title>
          </ImageContainer>
        </a>  
    </Container>
  )
}
