import React from 'react'
import { MediaAppearanceCard } from './cards/media-appearance-cards'
import { GridLayout } from './layout'
import { IGatsbyImageData } from "gatsby-plugin-image"

interface MediaAppearancesProps {
  data: {
    article_title: {
      text: string
    }
    date: string
    link: {
      url: string
    }
    medium: {
      text: string
    }
    image: {
      grayscale_image: { gatsbyImageData: IGatsbyImageData }
      original_image: { gatsbyImageData: IGatsbyImageData }
      alt: string
    }
  }[]
}

export const MediaAppearances = ({ data }: MediaAppearancesProps) => {
  return (
    <GridLayout>
      {data.map((mediaAppearance, index) => {
        const { article_title, image, link } = mediaAppearance

        return (
          <MediaAppearanceCard
            key={index}
            name={article_title.text}
            image={image}
            alt={image.alt}
            link={link.url}
          />
        )
      })}
    </GridLayout>
  )
}
