export const theme = {
  breakpoints: ['480px', '768px', '992px', '1200px', '1400px'],
  fontSizes: ['1rem', '1.2rem', '1.44rem', '1.728rem', '2.074rem', '2.488rem'],
  colors: {
    green: '#2DEC25',
    magenta: '#F164D8',
    yellow: '#F2F51D',
    peach: '#F7AAAA',
    blue: '#134BD9',
    lightBlue: '#76CCF9',
    lightGrey: '#BBBBBB',
    grey: '#E3E4E3',
    black: '#030303',
    white: '#FFF',
    transparent: 'transparent',
  },
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
  ],
  fontWeights: {
    normal: 500,
    semibold: 600,
    extraBold: 800,
  },
  borderWidths: [0, 1, 2],
}

export type Theme = typeof theme
