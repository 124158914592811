import React from 'react'
import { Flex, Box } from '../elements'
import { graphql, useStaticQuery } from 'gatsby'
import i18n from '../../config/i18n.js'
import { LanguageContextProps } from '../utils/types'
import styled from 'styled-components'

interface Props {
  data: {
    prismicContact: {
      data: {
        facebook: {
          url: string
        }
        instagram: {
          url: string
        }
      }
    }
  }
}

const Icon = styled.img`
  margin: 0 8px 0 0;
  height: 32px;
`
type FooterProps = LanguageContextProps

const Footer = ({ lang }: FooterProps) => {
  const { copyright } = i18n[lang]

  const data = useStaticQuery(graphql`
    query SocialMediaQuery {
      prismicContact {
        data {
          facebook {
            url
          }
          instagram {
            url
          }
        }
      }
    }
  `)
  const { facebook, instagram } = data.prismicContact.data
  return (
    <Flex width="100%" mt={8} flexDirection={['column', 'row']}>
      <a href={facebook.url} target="_blank">
        <Flex flexDirection="row" alignItems="center" mt={2} ml={[2, 0]} mb={2} mr={6}>
          <Icon src="/icons/facebook.svg" />
          <Box ml={[1, 0]} style={{ marginTop: '2px' }}>
            Facebook
          </Box>
        </Flex>
      </a>
      <a href={instagram.url} target="_blank">
        <Flex flexDirection="row" alignItems="center" mt={2} mb={2} mr={0}>
          <Icon src="/icons/instagram.svg" />
          <Box style={{ marginLeft: '0px', marginTop: '2px' }}>Instagram</Box>
        </Flex>
      </a>
      <Box mt={3} ml={[2, 3]} mb={3} flex="1" textAlign={['left', 'right']}>
        {copyright}
      </Box>
    </Flex>
  )
}

export default Footer
