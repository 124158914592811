import styled, { ThemeProps } from 'styled-components'
import {
  alignSelf,
  AlignSelfProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  flexBasis,
  FlexBasisProps,
  flexbox,
  FlexboxProps,
  FlexProps,
  grid,
  gridArea,
  GridAreaProps,
  gridColumn,
  GridColumnProps,
  GridProps,
  gridRow,
  GridRowProps,
  justifyContent,
  JustifyContentProps,
  justifyItems,
  JustifyItemsProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { motion, MotionProps } from 'framer-motion'
import { theme } from '../styles'

export type BoxProps = SpaceProps &
  LayoutProps &
  BorderProps &
  ColorProps &
  FlexProps &
  FlexBasisProps &
  AlignSelfProps &
  GridAreaProps &
  GridColumnProps &
  GridRowProps &
  TypographyProps

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${space}
  ${layout}
  ${color}
  ${border}
  ${typography}
  ${flex}
  ${flexBasis}
  ${alignSelf}
  ${gridArea}
  ${gridColumn}
  ${gridRow}
`

export const MotionBox = styled(motion.div)<BoxProps & MotionProps>`
  box-sizing: border-box;
  ${space}
  ${layout}
  ${color}
  ${border}
  ${typography}
  ${flex}
  ${flexBasis}
  ${alignSelf}
  ${gridArea}
  ${gridColumn}
  ${gridRow}
`

Box.displayName = 'Box'

type FlexCompProps = BoxProps & FlexboxProps

export const Flex = styled(Box)<FlexCompProps>`
  display: flex;
  ${flexbox}
`

Flex.displayName = 'Flex'

type GridCompProps = BoxProps & GridProps & JustifyContentProps & JustifyItemsProps

export const Grid = styled(Box)<GridCompProps>`
  display: grid;
  ${justifyContent}
  ${justifyItems}
  ${grid}
`

type ButtonProps = SpaceProps &
  LayoutProps &
  ColorProps &
  BorderProps &
  ThemeProps<typeof theme> & { hoverTextColor: string; hoverColor: string }

export const Button = styled.button<ButtonProps>`
  font-weight: 100;
  font-size: 16px;
  text-align: center;
  ${space}
  ${layout}
  ${color}
  ${border}

  &:hover {
    cursor: pointer;
    background-color: ${(data: ButtonProps) => data.hoverColor};
    color: ${(data: ButtonProps) => data.hoverTextColor};
  }
  &:focus {
    outline:0;
  }
`

Button.displayName = 'Button'

export const Justify = styled.div`
  font-size: 11px;
  h3 {
    margin-top: 40px;
  }
`
