import { componentResolverFromMap,PrismicRepositoryConfigs } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './linkResolver'
import ProjectTemplate from '../templates/project'
import ContactPage from '../templates/contact'
import ServicesPage from '../templates/services'
import ArtMomentsPage from '../templates/moments'
import AboutPage from '../templates/about'

export const repositoryConfigs: PrismicRepositoryConfigs = [
  {
    repositoryName: `${process.env.GATSBY_PRISMIC_REPO_NAME}`,
    linkResolver,
    componentResolver: componentResolverFromMap({
      about: AboutPage,
      project: ProjectTemplate,
      contact: ContactPage,
      services: ServicesPage,
      art_moments: ArtMomentsPage
    }),
  },
]
