import React from 'react'
import { Box, Flex } from '../../elements'

type Props = {
  title: string
  desc: string

}

type ServiceCardProps = Props 

export const ServiceCard = ({ title, desc }: ServiceCardProps) => {
  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" alignItems="flex-start">
        <Box my={5} color="black" fontSize="20px" fontWeight="semibold" style={{ textTransform: 'uppercase' }}>
        <h3 style={{ marginTop: '1.0rem' }}>{title}</h3> 
        </Box>
      </Flex>
      <Box dangerouslySetInnerHTML={{ __html: desc }} />
    </Flex>
  )
}
