import { GatsbyImage } from "gatsby-plugin-image"
import React from 'react'
import styled from 'styled-components'
import { Box } from '../elements'
import { IGatsbyImageData } from "gatsby-plugin-image"

const CoverImage = styled(GatsbyImage)`
  width: 100%;
  max-height: 600px;
`

interface HeroImageProps {
  original: IGatsbyImageData
  duotone: IGatsbyImageData
}

export const HeroImage = ({ original }: HeroImageProps) => (
  <Box pt={12} mx={[4, 4, 4, '59px']}>
    <CoverImage image={original && original ? original : undefined} alt=''/>
  </Box>
)
