const website = require('./website')

module.exports = {
  hu: {
    default: true,
    path: '',
    locale: 'hu-hu',
    siteLanguage: 'hu',
    ogLang: 'hu_HU',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: '',
    about: 'Rólunk',
    now: 'Jelen',
    history: 'Történetek',
    contact: 'Kapcsolat',
    moments: 'Art Moments',
    services: 'Szolgáltatások',
    block: 'Blokk',
    email_required: 'E-mail cím kitöltése kötelező!',
    email_invalid: 'Nem valós e-mail címet adtál meg.',
    successful_subscribe: 'Sikeres feliratkozás!',
    subscribe: 'Feliratkozom',
    participants: 'Program',
    program: 'Program',
    subscribe_error: 'Valami hiba történt, próbáld meg később',
    copyright: 'Copyright 2023 Hybridart',
    projects: 'Projektek',
    keywords: 'Kulcsszavak',
    imageLoadErrorMessage: 'Valami hiba történt, próbáld meg később',
    nextLabel: 'Következő',
    prevLabel: 'Előző',
    zoomInLabel: 'Nagyít',
    zoomOutLabel: 'Kicsinyít',
    closeLabel: 'Bezár',
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    about: 'About',
    now: 'Now',
    history: 'History',
    contact: 'Contact',
    moments: 'Art Moments',
    services: 'Services',
    block: 'Blokk',
    email_required: 'Email address is required!',
    email_invalid: 'Email address is invalid!',
    successful_subscribe: 'Successful subscription!',
    subscribe: 'Subscribe',
    participants: 'Program',
    program: 'Program',
    subscribe_error: 'Something went wrong try again later',
    copyright: 'Copyright 2023 Hybridart',
    projects: 'Projects',
    keywords: 'Keywords',
    imageLoadErrorMessage: 'Something went wrong try again later',
    nextLabel: 'Next Image',
    prevLabel: 'Previous Image',
    zoomInLabel: 'Zoom In',
    zoomOutLabel: 'Zoom Out',
    closeLabel: 'Close',
  },
}
