import React from 'react'
import { Box, Flex } from '../../elements'
import { GatsbyImage,IGatsbyImageData } from "gatsby-plugin-image"
import styled from 'styled-components'


type Props = {
  partner:  {
    link: {
      url: string
      
    }
    logo: {
      alt: string
      gatsbyImageData: IGatsbyImageData
    } 
  }

}

const Container = styled(Box)`
  background-position: bottom right;
  background-size: cover;
`

const Image = styled(GatsbyImage)`
  width: 150px;
  height: 150px;
`

type PartnerCardProps = Props 

export const PartnerCard = ({ partner }: PartnerCardProps) => {

  return (
    <Container>
        <Flex flexDirection="column" maxWidth="150px">
           <a href={partner.link.url} target="_blank">
             <Image image={partner.logo.gatsbyImageData} alt={partner.logo.alt ? partner.logo.alt : ''} />
           </a>
        </Flex>
    </Container>
  )
}
