import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from '../../elements'
import { theme } from '../../styles'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

const Container = styled(Box)`
  background-position: bottom right;
  background-size: cover;
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 256;
`

const Title = styled.h3`
  position: relative;
  top: 160px;
  margin-right: 128px;
  font-weight: 500;
  font-size: 16px;
  padding: 14px;
  text-transform: uppercase;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
`
const ImageContainer = styled(Box)`
  position: relative;
`


interface TeamMemberCardProps {
  name: string
  originalImage: { gatsbyImageData: IGatsbyImageData }
  grayscaleImage: { gatsbyImageData: IGatsbyImageData }
  alt?: string
}

export const TeamMemberCard = ({ name, alt, originalImage, grayscaleImage }: TeamMemberCardProps) => {
  const [hovered, setHovered] = useState(false)

  return (
    <Container onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      
        <ImageContainer>
          <Image image={hovered ? originalImage.gatsbyImageData:grayscaleImage.gatsbyImageData } alt={alt ? alt :''} />
          <Title>{name}</Title>
        </ImageContainer>
    </Container>
  )
}
