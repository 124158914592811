import { WindowLocation } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'
import i18n from '../../config/i18n.js'
import { Layout } from '../components/layout'
import SEO from '../components/SEO/index'
import { Box, Justify, Grid } from '../elements/index'
import { PageContextProps } from '../utils/types.js'
import { ServiceCard } from '../components/cards/service-card'
import { PartnerCard } from '../components/cards/partner-card'
import { ImageGallery } from '../components/gallery'

interface Props {
  data: {
    prismicServices: {
      _previewable: boolean
      data: {
        description: {
          html: string
          text: string
        }
        title: {
          text: string
        }
        service: {
          service_title: string
          service_description: string
        }
        body: any[]
      }
    }
  }
}

type ServicesPageProps = Props & PageContextProps & { location: WindowLocation }

const ServicesPage = ({ data, pageContext: { lang }, location }: ServicesPageProps) => {
  const { description, title, service, body } = data.prismicServices.data


  const galleries = body?.filter(slice => slice.slice_type === 'image_gallery')
  const partners = body?.filter(slice => slice.slice_type ==='partners')[0]


  return (
    <Layout lang={lang}>
      <SEO title={`${i18n[lang].services} | Hybridart`} desc={description.text} pathname={location.pathname} />
      <Box>
        <h2 style={{ marginTop: '1.0rem' }}>{title.text}</h2>
        <Justify dangerouslySetInnerHTML={{ __html: description.html }} />
         {service.map((service, index) => {
              return (
                  <ServiceCard key={index}
                  title={service.service_title.text}
                  desc={service.service_description.html}

                />
                )
            })}

        {galleries && (<ImageGallery data={galleries} lang={lang}/>)}

        <h2 style={{ marginTop: '1.0rem' }}>{partners.primary.partners_title.text}</h2>
        <Grid pb={5} pr={[0, 0, 0, 0]} gridTemplateColumns="repeat(auto-fit, minmax(150px, 1fr))" gridGap={5}>
           {partners.items.map((partner, index) => (
            <PartnerCard
             key={index}
             partner={partner}
            />
          ))}
         </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query ServicesQuery($lang: String!) {
    prismicServices(lang: { eq: $lang }) {
      _previewable
      data {
        description {
          html
        }
        title {
          text
        }
        service {
          service_description {
            html
          }
          service_title {
            text
          }
      }
        body {
          ... on PrismicServicesDataBodyImageGallery {
            slice_type
            primary { 
              type
              }
            items { 
              image { 
                thumb:gatsbyImageData (
                  width: 270
                  height: 270
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
            }
          }
          ... on PrismicServicesDataBodyPartners {
            slice_type
            primary { 
              partners_title {
                text
              }
              }
            items { 
              link {
                url
              }
              logo { 
                gatsbyImageData (
                  width: 150
                  height: 150
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
                alt
              }
            }
          }
        }
      }
    }
  }
`

export default ServicesPage