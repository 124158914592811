import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from '../../elements'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

const Image = styled(GatsbyImage)`
  width: 100%;
`

type Props = {
  name: string
  desc: string
  image: IGatsbyImageData
}

type StoryCardProps = Props & IGatsbyImageData

export const ParticipantCard = ({ name, desc, image }: StoryCardProps) => {
  return (
    <Flex flexDirection="column" maxWidth="600px">
      <Flex flexDirection="column" alignItems="flex-start">
        <Image image={image} alt='' />
        <Box my={5} color="black" fontSize="20px" fontWeight="semibold" style={{ textTransform: 'uppercase' }}>
          {name}
        </Box>
      </Flex>
      <Box dangerouslySetInnerHTML={{ __html: desc }} />
    </Flex>
  )
}
