import React from 'react'
import { Box, Flex,  } from '../elements'
import Gallery from '@browniebroke/gatsby-image-gallery'
import i18n from '../../config/i18n.js'
import { LanguageContextProps } from '../utils/types'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface GalleryProps {
  data: { 
    slice_type: string
    primary: {
      type: string
    }
     items: { 
            image: { 
              thumb: { 
               gatsbyImageData: IGatsbyImageData }
              full: {
                gatsbyImageData: IGatsbyImageData }
              alt: string
            }
          }[]
  }[]
  lang: string
}

type ImageGalleryProps = LanguageContextProps & GalleryProps


export const ImageGallery = ( {lang,  data} : ImageGalleryProps) => { 


const lightboxOptions  = {
    imageLoadErrorMessage: i18n[lang].imageLoadErrorMessage,
    nextLabel: i18n[lang].nextLabel,
    prevLabel: i18n[lang].prevLabel,
    zoomInLabel: i18n[lang].zoomInLabel,
    zoomOutLabel: i18n[lang].zoomOutLabel,
    closeLabel: i18n[lang].closeLabel,
  }

  const carouselOptions  = {
    showThumbs: false,
    showStatus: false,
    useKeyboardArrows: true,
    emulateTouch: true
  }

  return (
    <React.Fragment>
      {data.map((gallery,index)=> {
        const { items , primary } = gallery
        const images = items.map(({image})=> image)

        return (
            (primary.type === "Banner") ? 
            <Flex justifyContent="space-between" >
                <Box/>
                <Box width={[1, 1,1,2/3]}>
                  <Carousel {...carouselOptions} swipeable={true}>
                    {images.map((image) => { 
                      return (
                        <GatsbyImage
                          image={image.full}
                          alt={image.alt ? image.alt : ''}/>
                      )
                    })}
                </Carousel>
               </Box> 
              <Box/>
              </Flex>

            :
            <Box key={index} p={3}>
                 <Gallery images={images} lightboxOptions={lightboxOptions} />
             </Box>
        )})}
     </React.Fragment>
    )
}
