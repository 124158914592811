const { defaultLanguage } = require('../../prismic-configuration')

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'home': {
      return doc.lang === defaultLanguage ? '/' : `/${doc.lang}`
    }

    case 'project': {
      return doc.lang === defaultLanguage
        ? `/story/${doc.uid}`
        : `/story/${doc.lang}/${doc.uid}`
    }

    case 'contact': {
        return doc.lang === defaultLanguage ? 'contact' : `${doc.lang}/contact`
      }

    case 'abo': {
        return doc.lang === defaultLanguage ? 'about' : `${doc.lang}/about`
      }
    
    case 'services': {
        return doc.lang === defaultLanguage ? 'services' : `${doc.lang}/services`
    }

    case 'art_moments': {
        return doc.lang === defaultLanguage ? 'art-moments' : `${doc.lang}/art-moments`
    }

    case 'block_page': {
      return doc.lang === defaultLanguage ? 'blokk' : `${doc.lang}/block`
    }

    case 'static_page': {
      return doc.lang === defaultLanguage ? `/static/${doc.uid}`: `/static/${doc.lang}/${doc.uid}`
    }

    default:
      return '/'
  }
}